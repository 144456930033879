.video-ui-background {
    position: fixed;
    inset: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    transition: opacity 1s ease-in 0s;
    pointer-events: auto;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;    
}

.video-ui-modal {
    width: auto;
    position: relative;
    margin: 0;
    padding: 0;
    background: rgb(255, 255, 255);
    overflow: visible;
    border-radius: 24px;
    height: auto;
    max-width: 95%;
    max-height: 95%;
    display: flex;
    flex-direction: column;
}